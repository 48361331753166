import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { LoadingController, NavController } from '@ionic/angular';
import { AlertController, ToastController } from '@ionic/angular';
// import { Storage } from '@ionic/storage'; 
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Plugins } from '@capacitor/core';
import { environment } from './../environments/environment';
import { timeout } from 'rxjs/operators';
import { EncryptServiceService } from './encrypt-service.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/angular";
import * as i3 from "./encrypt-service.service";
import * as i4 from "@ionic-native/file-transfer/ngx/index";
import * as i5 from "ngx-device-detector";
const { Storage } = Plugins;
export class ConexionApiService {
    constructor(http, loadingCtrl, crypt, alertCtrl, toast, transfer, device, nav, encriptar) {
        this.http = http;
        this.loadingCtrl = loadingCtrl;
        this.crypt = crypt;
        this.alertCtrl = alertCtrl;
        this.toast = toast;
        this.transfer = transfer;
        this.device = device;
        this.nav = nav;
        this.encriptar = encriptar;
        this.ull_ = environment.api.ull_;
        this.uss_ = this.crypt.dec(environment.api.uss_);
        this.pss_ = this.crypt.dec(environment.api.pss_);
        this.isLoading = false;
    }
    // async setHash(key:string,objeto:any): Promise<void>{
    //   return await this.storage.set(key,objeto);
    // }
    postPatente(hash, id_sitio, dealer = false, id_inspeccion = '0') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(hash);
            let dataPost = "hash=" + hash + "&id_sitio" + id_sitio + '&dealer=' + dealer + '&webapp=falabella' + '&id_inspeccion=' + id_inspeccion;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            //console.log(dataPost);
            return new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información...",
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "getPatentes.php", dataPost, options)
                        .pipe(timeout(5000))
                        .subscribe(data => {
                        /* aqui va la data recibida */
                        this.loadingCtrl.dismiss();
                        if (data['status_code'] == "1") {
                            console.log("Patentes:", data['patentes']);
                            if (data['patentes'].length == 0) {
                                this.presentAlert("No se encuentran patentes disponibles");
                                return resolve(false);
                            }
                            else {
                                return resolve(data['patentes']);
                            }
                        }
                        else {
                            if (data['message'] != "") {
                                this.presentAlert(data['message']);
                            }
                            else {
                                this.presentToast("Se ha producido un error inesperado, por favor vuelva a intentarlo.");
                            }
                            return resolve(false);
                        }
                        /* termino de data recibida */
                    }, error => {
                        this.loadingCtrl.dismiss();
                        console.log(error);
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        //console.log(JSON.stringify(error));
                        return reject(error);
                    });
                });
            });
        });
    }
    postEstadoInspeccion(id_sitio, hash = null, url = null, tipo = 'persona') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let dataPost = "usuario=" + this.uss_ + "&password=" + this.pss_ + "&browser=" + id_sitio + '&hash=' + hash + '&type=' + tipo + '&url=' + url;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "getEstadoInspeccion.php", dataPost, options)
                        .pipe(timeout(5000))
                        .subscribe(data => {
                        this.loadingCtrl.dismiss();
                        console.log(data);
                        if (data['status_code'] == "1") {
                            let dato;
                            for (let datos of data['datos']) {
                                console.log("hash--recibido", datos.hash);
                                dato = datos.hash;
                            }
                            return resolve({ status: 1, hash: dato });
                        }
                        else if (data['status_code'] == "32") {
                            let dato;
                            for (let datos of data['datos']) {
                                console.log("hash--recibido", datos.hash);
                                dato = datos.hash;
                            }
                            return resolve({ status: 32, hash: dato });
                        }
                        else if (data['status_code'] == "30") {
                            let dato;
                            for (let datos of data['datos']) {
                                console.log("hash--recibido", datos.hash);
                                dato = datos.hash;
                            }
                            return resolve({ status: 30, hash: dato });
                        }
                        else {
                            this.presentAlert("Se ha producido un error inesperado, por favor vuelva a intentarlo.");
                            return resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.loadingCtrl.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        return reject(false);
                    });
                });
            });
        });
    }
    dealerHashValidate(hash) {
        return new Promise((res, rej) => {
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            this.http.post(this.ull_ + "SetLinkdealer.php", `hash=${hash}`, options)
                .pipe(timeout(5000))
                .subscribe(r => {
                if (r['status_code'] == '1') {
                    return res(true);
                }
                else {
                    return rej(false);
                }
            });
        });
    }
    dealerLogin(hash, id = 1) {
        let extra = {
            queryParams: {
                from: 'dealer'
            }, skipLocationChange: true
        };
        Storage.clear();
        Storage.set({ key: 'hash-validado', value: hash }).then(() => {
            this.nav.navigateRoot('/elegir-inspeccion', extra);
        });
    }
    postLogin(rut, email, telefono, browser, device, id_sitio, hash = null, tipo = 'persona') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(rut);
            let dataPost = "usuario=" + this.uss_ + "&password=" + this.pss_ + "&rut=" + rut + "&mail=" + email + "&telefono=" + telefono + "&browser=" + browser + "&device=" + device + "&id_sitio=" + id_sitio + '&hash=' + hash + '&type=' + tipo;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "getLogin.php", dataPost, options)
                        .pipe(timeout(5000))
                        .subscribe(data => {
                        this.loadingCtrl.dismiss();
                        console.log(data);
                        if (data['status_code'] == "1") {
                            console.log("--Validacion de hash por rut--");
                            if (data['datos'].length == 0) {
                                console.log("--No se encontraron patentes para el usuario--");
                                this.presentAlert("No se encuentran patentes disponibles");
                            }
                            else {
                                console.log("detalle Data -> ", data);
                                let dato;
                                for (let datos of data['datos']) {
                                    console.log("hash--recibido", datos.hash);
                                    //this.setHash('hash-validado', patentes.hash);
                                    dato = datos.hash;
                                }
                                return resolve(dato);
                            }
                        }
                        else if (data['status_code'] == "3") {
                            this.nav.navigateRoot('/culmino-tiempo');
                            //this.presentAlert(data['message']);
                        }
                        else {
                            if (data['message'] != "") {
                                this.presentAlert(data['message']);
                            }
                            else {
                                this.presentAlert("Se ha producido un error inesperado, por favor vuelva a intentarlo.");
                            }
                            return resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.loadingCtrl.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        return reject(false);
                    });
                });
            });
        });
    }
    presentAlert(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                message: message,
                subHeader: 'Alerta',
                buttons: ['Aceptar'],
                backdropDismiss: false
            });
            yield alert.present();
        });
    }
    presentToast(mensaje, duration = 2) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toast.create({
                message: mensaje,
                duration: duration * 1000,
                position: 'top'
            });
            toast.present();
        });
    }
    dismiss() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            while ((yield this.loadingCtrl.getTop()) !== undefined) {
                yield this.loadingCtrl.dismiss();
            }
        });
    }
    postFoto(foto_src, id_inspeccion, hash, documento, latitud, longitud) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let dataPost = "imagen=" + foto_src + "&nombre_imagen=" + documento + "&id_inspeccion=" + id_inspeccion + "&hash=" + hash + "&latitud=" + latitud + "&longitud=" + longitud;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            //  console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setInspeccionImagen.php", dataPost, options)
                        .subscribe((data) => {
                        this.dismiss();
                        let url = "";
                        let status = "";
                        let tiempo = "";
                        //if (this.device.getDeviceInfo().device == "iPhone") {
                        //url += 'https://denuncio.clubautomotriz.cl/web/test_redirect_img.php?url=';
                        //}
                        // url += 'https://denuncio.clubautomotriz.cl/web/test_redirect_img.php?url=';
                        url = data['url'];
                        status = data['status'];
                        tiempo = data['tiempo'];
                        // console.log("Data Response: ", url);
                        if (data["status_code"] == 1) {
                            resolve([true, url, status, tiempo]);
                        }
                        else {
                            resolve([false, data['message']]);
                        }
                    }, (err) => {
                        console.log(err);
                        this.dismiss();
                        resolve([false, 'Ocurrió un error']);
                    });
                });
            });
        });
    }
    postFotoAccesorio(foto_src, id_inspeccion, hash, documento, latitud, longitud) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let dataPost = "imagen=" + foto_src + "&nombre_imagen=" + documento + "&id_inspeccion=" + id_inspeccion
                + "&hash=" + hash + "&latitud=" + latitud + "&longitud=" + longitud;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            //console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                // sin el load controler 
                // misma recomendación de la funcion anterior ^^
                this.loadingCtrl.create({
                    message: "Cargando Información"
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setImagenAccesorio.php", dataPost, options)
                        .subscribe((data) => {
                        this.dismiss();
                        console.log("Data response: ", data);
                        if (data['status_code'] == 1) {
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                    }, (err) => {
                        this.dismiss();
                        console.error(err);
                        this.presentToast("Se ha producido un error inesperado, vuelva a intentarlo");
                    });
                });
            });
        });
    }
    cierreproceso(idinspeccion, hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(idinspeccion);
            let dataPost = "hash=" + hash + "&id_inspeccion=" + idinspeccion;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setInspeccionFinProceso.php", dataPost, options).subscribe(data => {
                        console.log(data);
                        if (data != null) {
                            if (data['status_code'] == "1") {
                                this.loadingCtrl.dismiss();
                                return resolve(true);
                            }
                            else {
                                this.dismiss();
                                this.presentToast(data['message']);
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    selecciondanios(hash, idinspeccion, danio, nombre_imagen) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(idinspeccion);
            let dataPost = "hash=" + hash + "&id_inspeccion=" + idinspeccion + "&danios=" + danio + "&nombre_imagen=" + nombre_imagen;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setInspeccionDanosSeleccionados.php", dataPost, options).subscribe(data => {
                        console.log(data);
                        if (data != null) {
                            if (data['status_code'] == "1") {
                                this.loadingCtrl.dismiss();
                                resolve(true);
                            }
                            else {
                                if (data['message'] != "") {
                                    this.loadingCtrl.dismiss();
                                    this.presentAlert(data['message']);
                                    resolve(false);
                                }
                                else {
                                    this.loadingCtrl.dismiss();
                                    this.dismiss();
                                    this.presentToast("Se ha producido un error inesperado, por favor vuelva a intentarlo.");
                                    resolve(false);
                                }
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    get_status(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let status = yield Storage.get({ key: '_active_' + id });
            status = JSON.parse(status.value);
            if (status['status'] != true) {
                this.presentAlert("La inspección " + id + " ya fue finalizada");
                yield Storage.clear().then(() => {
                    this.nav.navigateRoot(['/']);
                });
            }
            else {
                return console.log("Aún no se ha cerrado");
            }
            // console.log(status['status']);
        });
    }
    getImagenesError(hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(hash);
            let dataPost = "hash=" + hash;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "getImagenesError.php", dataPost, options).subscribe(data => {
                        console.log(data);
                        if (data != null) {
                            if (data['status_code'] == "1") {
                                this.loadingCtrl.dismiss();
                                return resolve(data);
                            }
                            else {
                                this.loadingCtrl.dismiss();
                                return resolve(false);
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.loadingCtrl.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    CorrecionImagenes(hash, idinspeccion) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(hash);
            let dataPost = "hash=" + hash + "&id_inspeccion=" + idinspeccion;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setCorreccionImagenesFinProceso.php", dataPost, options).subscribe(data => {
                        console.log(data);
                        if (data != null) {
                            if (data['status_code'] == "1") {
                                this.loadingCtrl.dismiss();
                                return resolve(true);
                            }
                            else {
                                this.dismiss();
                                return resolve(false);
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    /** Seleccinoa una inspección para post-procesar en backend */
    setSeleccionInspeccion(hash, id, flag_webapp = false) {
        let data = `hash=${hash}&id=${id}`;
        if (flag_webapp == true) {
            data += "&flag_webapp=1";
        }
        let options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        return new Promise((resolve, reject) => {
            this.http.post(this.ull_ + "setSeleccionDeInspeccion.php", data, options).pipe(timeout(7000)).subscribe(r => {
                console.log(r);
                return resolve(r);
            }, err => {
                return reject(err);
            });
        });
    }
    GetContador(hash, idinspeccion, flag_webapp = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(hash);
            let dataPost = "hash=" + hash + "&id_inspeccion=" + idinspeccion;
            if (flag_webapp != false)
                dataPost += "&flag_webapp=1";
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "getContadorAutoinspeccion.php", dataPost, options).subscribe(data => {
                        console.log("=========== getContadorAutoinspeccion2 ==========");
                        console.log(data);
                        if (data != null) {
                            let tiempo = data['tiempo'];
                            let status = data['status'];
                            const imagenes = data['imagenes'];
                            const imagenes_accesorio = data['image_accesorio'];
                            // console.log("Data Response: ", url);
                            if (data.hasOwnProperty('intento') && data.hasOwnProperty('intentos_permitidos')) {
                                Storage.set({ key: 'int', value: data['intento'] });
                                Storage.set({ key: 'intp', value: data['intentos_permitidos'] });
                            }
                            if (data["status_code"] == 1) {
                                this.loadingCtrl.dismiss();
                                resolve([true, tiempo, status, imagenes, imagenes_accesorio]);
                            }
                            else if (data["status_code"] == 3) {
                                this.dismiss();
                                this.nav.navigateRoot('/reactivar');
                            }
                            else if (data["status_code"] == 4) {
                                this.dismiss();
                                this.nav.navigateRoot('/paso-dos-inspeccion-home'); //elegir-inspeccion');
                            }
                            else {
                                this.dismiss();
                                return resolve(false);
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    GetDeducible(hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(hash);
            let dataPost = "hash=" + hash;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "getDeducibleUf.php", dataPost, options).subscribe(data => {
                        console.log(data);
                        if (data != null) {
                            // console.log("Data Response: ", url);
                            let uf = data['dedusible_uf'];
                            let hash = data['hash_login'];
                            let login = data['id_inspeccion'];
                            let deducibles = data['listado_deducibles'];
                            if (data["status_code"] == 1) {
                                this.loadingCtrl.dismiss();
                                resolve([true, uf, hash, login, deducibles]);
                            }
                            else {
                                this.dismiss();
                                return resolve(false);
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    SetAprobacion(hash, idinspeccion, estado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(hash);
            let dataPost = "hash=" + hash + "&id_inspeccion=" + idinspeccion + "&estado=" + estado;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setAprobacionCliente.php", dataPost, options).subscribe(data => {
                        console.log(data);
                        if (data != null) {
                            if (data["status_code"] == 1) {
                                this.loadingCtrl.dismiss();
                                resolve(true);
                            }
                            else {
                                this.dismiss();
                                return resolve(false);
                            }
                        }
                        else {
                            this.loadingCtrl.dismiss();
                            this.presentAlert("ERROR");
                            resolve(false);
                        }
                    }, error => {
                        console.log(error);
                        this.dismiss();
                        this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                        console.log(JSON.stringify(error));
                        resolve(false);
                    });
                });
            });
        });
    }
    btnWspAyuda() {
        let data = localStorage.getItem("_cap__storaged");
        let mensaje;
        data = JSON.parse(data);
        if (data) {
            mensaje = `Hola necesito ayuda con mi Inspección Digital Numero: ${data.id} Vehículo: ${data.marca} ${data.modelo} ${data.ano ? data.ano : ''}`;
            mensaje = encodeURI(mensaje);
        }
        else {
            mensaje = `Hola necesito ayuda para realizar mi Inspección Digital`;
            mensaje = encodeURI(mensaje);
        }
        window.open("https://wa.me/" + environment.contacto.wsp + "?text=" + mensaje, '_blank', 'location=yes');
    }
    GetRutinfo(rut, hash = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(rut);
            let dataPost = "rut=" + rut + "&hash=" + hash;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            console.log(dataPost);
            return yield new Promise((resolve, reject) => {
                this.http.post(this.ull_ + "getEmailInfo.php", dataPost, options).subscribe(data => {
                    console.log(data);
                    if (data != null) {
                        if (data["status_code"] == 1) {
                            return resolve([true, this.encriptar.dec(data["email"])]);
                        }
                        else {
                            return resolve(false);
                        }
                    }
                    else {
                        this.presentAlert("ERROR");
                        resolve(false);
                    }
                }, error => {
                    console.log(error);
                    this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                    console.log(JSON.stringify(error));
                    resolve(false);
                });
            });
        });
    }
    getDatosVehiculo(hash = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let dataPost = "hash=" + hash;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            return yield new Promise((resolve, reject) => {
                this.http.post(this.ull_ + "getDatosVehiculo.php", dataPost, options).subscribe(data => {
                    if (data != null) {
                        if (data["status_code"] == 1) {
                            return resolve([true, data]);
                        }
                    }
                    else {
                        this.presentAlert("ERROR");
                        resolve(false);
                    }
                }, error => {
                    console.log(error);
                    this.presentAlert("Problemas de conexion por favor intentelo mas tarde");
                    console.log(JSON.stringify(error));
                    resolve(false);
                });
            });
        });
    }
    postDocumento(documento_src, extension, id_inspeccion, hash, documento, latitud, longitud) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let dataPost = "documento=" + documento_src + "&extension=" + extension + "&nombre_documento=" + documento + "&id_inspeccion=" + id_inspeccion + "&hash=" + hash + "&latitud=" + latitud + "&longitud=" + longitud;
            let options = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            };
            return yield new Promise((resolve, reject) => {
                this.loadingCtrl.create({
                    message: "Cargando información..."
                }).then((res) => {
                    res.present();
                    this.http.post(this.ull_ + "setInspeccionDocumento.php", dataPost, options)
                        .subscribe((data) => {
                        this.dismiss();
                        let url = "";
                        let status = "";
                        let tiempo = "";
                        url = data['url'];
                        status = data['status'];
                        tiempo = data['tiempo'];
                        if (data["status_code"] == 1) {
                            resolve([true, url, status, tiempo]);
                        }
                        else {
                            resolve([false, data['message']]);
                        }
                    }, (err) => {
                        console.log(err);
                        this.dismiss();
                        resolve([false, 'Ocurrió un error']);
                    });
                });
            });
        });
    }
}
ConexionApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConexionApiService_Factory() { return new ConexionApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoadingController), i0.ɵɵinject(i3.EncryptServiceService), i0.ɵɵinject(i2.AlertController), i0.ɵɵinject(i2.ToastController), i0.ɵɵinject(i4.FileTransfer), i0.ɵɵinject(i5.DeviceDetectorService), i0.ɵɵinject(i2.NavController), i0.ɵɵinject(i3.EncryptServiceService)); }, token: ConexionApiService, providedIn: "root" });
